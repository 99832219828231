<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          cyan: colors.cyan,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
  -->
  <div>
    <Html class="h-full bg-white">
      <Head>
        <Title>WeTix Admin</Title>
      </Head>
    </Html>
    <Body class="h-full"></Body>
    <div v-if="accountSetupComplete == true">
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="fixed inset-0 z-40 flex md:hidden" @close="sidebarOpen = false">
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <div class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-14 p-1">
                  <button
                    type="button"
                    class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:bg-gray-600"
                    @click="sidebarOpen = false"
                  >
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    <span class="sr-only">Close sidebar</span>
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 px-4 flex items-center">
                <img class="h-8 w-auto" src="~/assets/wetix-logo.svg" alt="Wetix" />
              </div>
              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="h-full flex flex-col">
                  <div class="space-y-1">
                    <div>MOBILE NAV</div>
                    <a
                      v-for="item in navigation"
                      :key="item.name"
                      :href="item.href"
                      :class="[
                        item.current
                          ? 'bg-purple-50 border-purple-600 text-purple-600'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 py-2 px-3 flex items-center text-base font-medium',
                      ]"
                      :aria-current="item.current ? 'page' : undefined"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          item.current
                            ? 'text-purple-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-4 flex-shrink-0 h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </a>
                  </div>
                  <div class="mt-auto pt-10 space-y-1">
                    <a
                      v-for="item in secondaryNavigation"
                      :key="item.name"
                      :href="item.href"
                      class="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                    >
                      <component
                        :is="item.icon"
                        class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <nav
          class="bg-gray-50 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto"
        >
          <div class="flex-shrink-0 px-4 flex items-center">
            <img class="h-6 w-auto" src="~/assets/wetix-logo.svg" alt="Wetix" />
          </div>
          <div class="flex-grow mt-5">
            <div class="space-y-1">
              <!-- ##########   DESKTOP NAV  ########## -->
              <div>DESKTOP NAV</div>
              <div>{{ }}</div>
              <NuxtLink
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                active-class="bg-purple-50 border-purple-600 text-purple-600"
                class="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
              >
                <component
                  :is="item.icon"
                  :class="[
                    item.current
                      ? 'text-purple-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </NuxtLink>
            </div>
          </div>
          <div class="flex-shrink-0 block w-full">
            <a
              v-for="item in secondaryNavigation"
              :key="item.name"
              :href="item.href"
              class="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
            >
              <component
                :is="item.icon"
                class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                aria-hidden="true"
              />
              {{ item.name }}
            </a>
          </div>
          <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
            <a href="#" class="flex-shrink-0 w-full group block">
              <div class="flex items-center">
                <div>
                  <img
                    class="inline-block h-9 w-9 rounded-full"
                    src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                    alt="User Image"
                  />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">User Name</p>
                  <p
                    class="text-xs font-medium text-gray-500 group-hover:text-gray-700"
                  >Tunes Festivals</p>
                </div>
              </div>
            </a>
          </div>
        </nav>
      </div>

      <!-- Content area -->
      <div class="md:pl-64">
        <div class="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
          <div class="sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
            <button
              type="button"
              class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
              @click="sidebarOpen = true"
            >
              <span class="sr-only">Open sidebar</span>
              <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
            </button>
            <div class="flex-1 flex justify-between px-4 md:px-0">
              <div class="flex-1 flex">
                <form class="w-full flex md:ml-0" action="#" method="GET">
                  <label for="mobile-search-field" class="sr-only">Search</label>
                  <label for="desktop-search-field" class="sr-only">Search</label>
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <SearchIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      name="mobile-search-field"
                      id="mobile-search-field"
                      class="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden"
                      placeholder="Search"
                      type="search"
                    />
                    <input
                      name="desktop-search-field"
                      id="desktop-search-field"
                      class="hidden h-full w-full border-transparent py-2 pl-8 pr-3 text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:block"
                      placeholder="Search customers, orders, and more"
                      type="search"
                    />
                  </div>
                </form>
              </div>
              <div class="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  class="bg-white rounded-full p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <BellIcon class="h-6 w-6" aria-hidden="true" />
                  <span class="sr-only">View notifications</span>
                </button>
              </div>
            </div>
          </div>

          <slot />
        </div>
      </div>
    </div>
    <div v-else class="p-8">
      <TenantAdminProfileSettingsVue />
    </div>
  </div>
</template>

<script setup lang="ts">


const navigation = [
  { name: "Dashboard", href: "/app", icon: HomeIcon, current: true },
  { name: "Events", href: "/app/events", icon: CalendarIcon, current: false },
  { name: "Tickets", href: "/app/tickets", icon: TicketIcon, current: false },
  { name: "Orders", href: "/app/orders", icon: CollectionIcon, current: false },
  {
    name: "Customers",
    href: "/app/customers",
    icon: UserGroupIcon,
    current: false,
  },
  {
    name: "Reports",
    href: "/app/reports",
    icon: DocumentReportIcon,
    current: false,
  },
];
const secondaryNavigation = [
  { name: "Settings", href: "/app/settings", icon: CogIcon },
  { name: "Help", href: "/app/help", icon: QuestionMarkCircleIcon },
  { name: "Platform Admin", href: "/app/platform", icon: LibraryIcon },
];
const tabs = [
  { name: "General", href: "#", current: true },
  { name: "Password", href: "#", current: false },
  { name: "Notifications", href: "#", current: false },
  { name: "Plan", href: "#", current: false },
  { name: "Billing", href: "#", current: false },
  { name: "Team Members", href: "#", current: false },
];

const accountSetupComplete = ref(true);


const sidebarOpen = ref(false);
const automaticTimezoneEnabled = ref(true);
const autoUpdateApplicantDataEnabled = ref(false);

// return {
//   navigation,
//   secondaryNavigation,
//   tabs,
//   sidebarOpen,
//   automaticTimezoneEnabled,
//   autoUpdateApplicantDataEnabled,
//   accountSetupComplete,
// };
</script>


<script lang="ts">
import { ref } from "vue";
import TenantAdminProfileSettingsVue from "~~/components/tenant-admin/TenantAdminProfileSettings.vue";
import {
  Dialog,
  DialogOverlay,
  Switch,
  SwitchGroup,
  SwitchLabel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  BellIcon,
  BriefcaseIcon,
  ChatIcon,
  CogIcon,
  DocumentSearchIcon,
  HomeIcon,
  MenuAlt2Icon,
  QuestionMarkCircleIcon,
  UsersIcon,
  CalendarIcon,
  CollectionIcon,
  TicketIcon,
  DocumentReportIcon,
  UserGroupIcon,
  LibraryIcon,
  XIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";


export default {
  components: {
    Dialog,
    DialogOverlay,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    TenantAdminProfileSettingsVue,
  },
  // setup() {

  // },
};
</script>
